import React, { useState } from "react";
import { Stack, Persona, Text, ContextualMenu } from "office-ui-fabric-react";

export interface ILoginProps {
	userName?: string;
	error?: string;
	onLogin: () => void;
	onLogout: () => void;
}

export const Login = ({ userName, error, onLogin, onLogout }: ILoginProps) => {
	const [signInMenuVisible, setSignInMenuVisible] = useState(false);

	const linkRef = React.useRef(null);

	if (error) {
		return <Text>{error}</Text>;
	}
	if (userName) {
		return (
			<Stack horizontal>
				<span ref={linkRef} style={{ cursor: "pointer" }}>
					<Persona onClick={() => setSignInMenuVisible(true)} />
				</span>
				<ContextualMenu
					items={[{ key: "logout", text: "Logout", onClick: onLogout }]}
					hidden={!signInMenuVisible}
					target={linkRef}
					onDismiss={() => setSignInMenuVisible(false)}
					onItemClick={() => setSignInMenuVisible(false)}
				/>
				<Text>Welcome {userName}!</Text>
			</Stack>
		);
	}

	return (
		<Stack horizontal>
			<span ref={linkRef} style={{ cursor: "pointer" }}>
				<Persona onClick={() => setSignInMenuVisible(true)} />
			</span>
			<ContextualMenu
				items={[{ key: "login", text: "Login", onClick: onLogin }]}
				hidden={!signInMenuVisible}
				target={linkRef}
				onDismiss={() => setSignInMenuVisible(false)}
				onItemClick={() => setSignInMenuVisible(false)}
			/>
			<Text>Welcome, you have read only access.</Text>
		</Stack>
	);
};
