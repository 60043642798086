import React from "react";
import { useHistory } from "react-router-dom";
import { Nav, INavLinkGroup, INavLink } from "office-ui-fabric-react/lib/Nav";
import { authModule } from "services/auth/AuthModule";
import { Location } from "history";

export interface IMainNavProps {
	pathName: string;
}

export const MainNav = ({ pathName }: IMainNavProps) => {
	const history = useHistory();

	const allNavLinks: INavLink[] = [
		{
			name: "Home",
			key: "home",
			url: "/",
		},
		{
			name: "Daily Login Tracker",
			key: "login-tracker",
			url: "/login-tracker",
		},
		{ name: "Login Calendar", key: "login-calendar", url: "/login-calendar" },
		{ name: "Daily AQ Tracker", key: "aq-tracker", url: "/aq-tracker" },
		{ name: "AQ Tracker Calendar", key: "aq-calendar", url: "/aq-calendar" },
	];

	const navLinkGroups: INavLinkGroup[] = [
		{
			links: authModule.isAuthenticated()
				? allNavLinks
				: allNavLinks.filter((navLink) => !navLink.authenticated),
		},
	];

	const getSelectedNavKey = (pathname: string) => {
		const splitPathName = pathname.split("/");
		if (splitPathName.length === 0) {
			return undefined;
		}
		if (splitPathName[1].length === 0) {
			return allNavLinks[0].key;
		}

		const lookingForPathName = `/${splitPathName[1]}`;

		const navLinkIndex = allNavLinks.findIndex(
			(navLink) => navLink.url === lookingForPathName
		);
		if (navLinkIndex < 0) {
			return undefined;
		}

		return allNavLinks[navLinkIndex].key;
	};

	return (
		<Nav
			selectedKey={getSelectedNavKey(pathName)}
			groups={navLinkGroups}
			onLinkClick={(ev?, item?: INavLink) => {
				ev?.preventDefault();
				if (item) {
					history.push(item.url);
				} else {
					console.warn("'item' was not passed into onLinkClick nav item event");
				}
			}}
		/>
	);
};

export interface IMainNavFromRouteProps {
	location: Location;
}

export const MainNavFromRoute = (props: IMainNavFromRouteProps) => {
	return MainNav({ ...props, pathName: props.location.pathname });
};
