import { IF4roReadOnlyService } from "./";
import {
	F4ROMember,
	MemberLoginListItem,
	AQMaster,
	AQTrackerListItem,
} from "types/MemberTypes";

type F4roServiceData = {
	members: { name: string; id: number }[];
	loginTracker: { id: number; memberLookupId: number; loginDate: Date }[];
	aqMaster: { id: number; aqDate: Date }[];
	aqTracker: { id: number; aqMasterLookupId: number; memberLookupId: number }[];
};

let _data: F4roServiceData;
async function getData(): Promise<F4roServiceData> {
	if (!_data) {
		const response = await fetch("/data.json");
		_data = await response.json();
		_data.loginTracker = _data.loginTracker.map((item) => ({
			...item,
			loginDate: new Date(item.loginDate),
		}));
	}

	return _data;
}

const getMemberList = async (): Promise<F4ROMember[]> => {
	const f4roData = await getData();
	const unsortedList = f4roData.members;
	const sortedList = unsortedList.sort((a, b) =>
		a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
	);
	return Promise.resolve(sortedList);
};

const getMemberLoginList = async (
	loginDate: Date
): Promise<MemberLoginListItem[]> => {
	const f4roData = await getData();
	const filteredLoginList = f4roData.loginTracker.filter(
		(item) => item.loginDate.toISOString() === loginDate.toISOString()
	);
	return Promise.resolve(filteredLoginList);
};

const getMemberLoginCalendar = async (
	memberId: number
): Promise<MemberLoginListItem[]> => {
	const f4roData = await getData();
	const filteredLoginList = f4roData.loginTracker.filter(
		(item) => item.memberLookupId === memberId
	);
	return Promise.resolve(filteredLoginList);
};

const getAQMaster = async (): Promise<AQMaster[]> => {
	const f4roData = await getData();
	const unsortedList = f4roData.aqMaster.map((item) => ({
		...item,
		aqDate: new Date(item.aqDate),
	}));

	return Promise.resolve(unsortedList);
};

const getAQTracker = async (
	aqMasterId: number
): Promise<AQTrackerListItem[]> => {
	const f4roData = await getData();
	const filteredAQTracker = f4roData.aqTracker.filter(
		(item) => item.aqMasterLookupId === aqMasterId
	);
	return Promise.resolve(filteredAQTracker);
};

const getMemberAQCalendar = async (
	memberId: number
): Promise<AQTrackerListItem[]> => {
	const f4roData = await getData();
	const filteredAQTracker = f4roData.aqTracker.filter(
		(item) => item.memberLookupId === memberId
	);
	return Promise.resolve(filteredAQTracker);
};

export const f4roReadOnlyService: IF4roReadOnlyService = {
	getMemberList,
	getMemberLoginList,
	getMemberLoginCalendar,
	getAQMaster,
	getAQTracker,
	getMemberAQCalendar,
};
