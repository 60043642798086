import React from "react";
import { MemberLogin } from "./MemberLogin";
import { F4ROMemberLogin } from "types/MemberTypes";
import { Stack } from "office-ui-fabric-react";

export interface IMemberListProps {
	memberLoginList?: F4ROMemberLogin[];
	onMemberLoginToggled: (memberId: number, id?: number) => void;
}

export const MemberLoginList = ({
	memberLoginList,
	onMemberLoginToggled,
}: IMemberListProps) => {
	if (memberLoginList) {
		return (
			<Stack tokens={{ childrenGap: 5, padding: 10 }}>
				{memberLoginList.map((memberLogin) => (
					<MemberLogin
						key={memberLogin.member.name}
						name={memberLogin.member.name}
						loggedIn={memberLogin.loggedIn}
						onLoginToggled={() =>
							onMemberLoginToggled(memberLogin.member.id, memberLogin.id)
						}
					/>
				))}
			</Stack>
		);
	}

	return <p>Loading...</p>;
};
