import React from "react";
import { MemberAQParticipation } from "./MemberAQParticipation";
import { AQTracker } from "types/MemberTypes";
import { Stack } from "office-ui-fabric-react";

export interface IMemberAQParticipationListProps {
  memberAQParticipationList?: AQTracker[];
  onParticipationToggled: (memberId: number, aqTrackerId?: number) => void;
}

export const MemberAQParticipationList = ({
  memberAQParticipationList,
  onParticipationToggled,
}: IMemberAQParticipationListProps) => {
  if (memberAQParticipationList) {
    return (
      <Stack tokens={{ childrenGap: 5, padding: 10 }}>
        {memberAQParticipationList.map((item) => (
          <MemberAQParticipation
            key={item.member.name}
            name={item.member.name}
            participated={item.participated}
            onParticipationToggled={() =>
              onParticipationToggled(item.member.id, item.id)
            }
          />
        ))}
      </Stack>
    );
  }

  return <p>Loading...</p>;
};
