import React, { useEffect, useState } from "react";
import {
	Dropdown,
	Spinner,
	IDropdownOption,
	Stack,
	IconButton,
} from "office-ui-fabric-react";
import { AQMaster } from "types/MemberTypes";
import { IF4roReadOnlyService } from "services/f4roService";

interface IAQDropdownProps {
	aqMasterId?: number;
	f4roReadOnlyService: IF4roReadOnlyService;
	aqMasterSelected: (memberId: number) => void;
}

const AQDropdown = ({
	aqMasterId,
	f4roReadOnlyService,
	aqMasterSelected,
}: IAQDropdownProps) => {
	const [aqMasterList, setAQMasterList] = useState<AQMaster[]>();

	useEffect(() => {
		f4roReadOnlyService.getAQMaster().then((data) => setAQMasterList(data));
	}, [f4roReadOnlyService]);

	useEffect(() => {
		if (aqMasterList && aqMasterList.length > 0) {
			if (aqMasterId === undefined) {
				aqMasterSelected(aqMasterList[aqMasterList.length - 1].id);
			} else if (
				aqMasterList.findIndex((item) => {
					return item.id === aqMasterId;
				}) < 0
			) {
				aqMasterSelected(aqMasterList[0].id);
			}
		}
	}, [aqMasterList, aqMasterId, aqMasterSelected]);

	function handleOnChange(
		ev: React.FormEvent<HTMLDivElement>,
		option?: IDropdownOption
	) {
		if (option) {
			const key = option.key as number;
			aqMasterSelected(key);
		}
	}

	const handleChevronClick = (leftClick: boolean) => {
		if (!aqMasterList) {
			return;
		}

		let index = aqMasterList.findIndex((item) => item.id === aqMasterId);
		if (index < 0) {
			return;
		}

		if (leftClick) {
			if (index === 0) {
				index = aqMasterList.length - 1;
			} else {
				index--;
			}
		} else {
			if (index + 1 === aqMasterList.length) {
				index = 0;
			} else {
				index++;
			}
		}

		aqMasterSelected(aqMasterList[index].id);
	};

	if (aqMasterList) {
		return (
			<Stack horizontal horizontalAlign="space-between">
				<IconButton
					iconProps={{ iconName: "ChevronLeft" }}
					onClick={() => handleChevronClick(true)}
				/>
				<Dropdown
					dropdownWidth={250}
					styles={{ root: { width: "250px" } }}
					selectedKey={aqMasterId}
					options={aqMasterList.map((item) => ({
						key: item.id,
						text: new Date(item.aqDate).toDateString(),
					}))}
					onChange={handleOnChange}
				/>
				<IconButton
					iconProps={{ iconName: "ChevronRight" }}
					onClick={() => handleChevronClick(false)}
				/>
			</Stack>
		);
	}
	return <Spinner />;
};

export default AQDropdown;
