var getMappingFunction = function (map, key) {
    var value = map[key];
    if (typeof value === "string")
        return mapNoTransform(value);
    if (value === null)
        return mapNoTransform(key);
    return value;
};
var mapSingleObject = function (objectA, map) {
    var objectB = {};
    Object.keys(map).forEach(function (key) {
        var mappingFunction = getMappingFunction(map, key);
        objectB[key] = mappingFunction(objectA);
    });
    return objectB;
};
export var mapObject = function (objectA, map) {
    if (objectA instanceof Array) {
        return objectA.map(function (obj) { return mapSingleObject(obj, map); });
    }
    else {
        return mapSingleObject(objectA, map);
    }
};
export var mapNoTransform = function (objectAKey) { return function (objectA) { return objectA[objectAKey]; }; };
export var mapDate = function (objectAKey) { return function (objectA) { return new Date(objectA[objectAKey]); }; };
export var mapNested = function (objectAKey, map) { return function (objectA) { return mapObject(objectA[objectAKey], map); }; };
export var mapLookup = function (objectAKey, parentTable, lookupPredicateBuilder, map) { return function (objectA) {
    var parentRecord = parentTable.find(lookupPredicateBuilder(objectA[objectAKey]));
    if (parentRecord && map) {
        return mapObject(parentRecord, map);
    }
    return parentRecord;
}; };
