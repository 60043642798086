import React, { useReducer, useEffect } from "react";
import { IF4roWriteService, IF4roReadOnlyService } from "services/f4roService";
import { MemberLoginList } from "./MemberLoginList";
import {
	DatePicker,
	Stack,
	IconButton,
	Spinner,
	SpinnerSize,
} from "office-ui-fabric-react";
import { dayAfter, dayBefore } from "utils/DateUtil";
import {
	getInitialMemberLoginList,
	getHandleMemberLoginToggled,
	IState,
} from "./memberLoginListContainerUtils";

export interface IMemberLoginListContainerProps {
	f4roReadOnlyService: IF4roReadOnlyService;
	f4roWriteService: IF4roWriteService | null;
}

type StateReducerArg = Partial<IState>;

type StateReducer = (state: IState, newState: StateReducerArg) => IState;
const stateReducer: StateReducer = (state, newState) => ({
	...state,
	...newState,
});
const initialState: IState = {
	memberLoginList: null,
	displayDate: new Date(),
};
const initialStateSetter = (state: IState) => state;

export const MemberLoginListContainer = ({
	f4roReadOnlyService,
	f4roWriteService,
}: IMemberLoginListContainerProps) => {
	const [state, setState] = useReducer<StateReducer, IState>(
		stateReducer,
		initialState,
		initialStateSetter
	);

	useEffect(() => {
		if (!state.memberLoginList) {
			getInitialMemberLoginList(f4roReadOnlyService, state, setState);
		}
	}, [f4roReadOnlyService, state]);

	const handleMemberLoginToggled = getHandleMemberLoginToggled(
		f4roWriteService,
		state,
		setState
	);

	return state.memberLoginList ? (
		<Stack tokens={{ childrenGap: 5, padding: 10 }}>
			<Stack horizontal>
				<IconButton
					iconProps={{ iconName: "ChevronLeft" }}
					onClick={() =>
						setState({
							displayDate: dayBefore(state.displayDate),
							memberLoginList: null,
						})
					}
				/>
				<DatePicker
					value={state.displayDate}
					onSelectDate={(selectedDate) => {
						setState({
							displayDate: selectedDate || state.displayDate,
							memberLoginList: null,
						});
					}}
				/>
				<IconButton
					iconProps={{ iconName: "ChevronRight" }}
					onClick={() =>
						setState({
							displayDate: dayAfter(state.displayDate),
							memberLoginList: null,
						})
					}
				/>
			</Stack>
			<MemberLoginList
				memberLoginList={state.memberLoginList}
				onMemberLoginToggled={handleMemberLoginToggled}
			/>
		</Stack>
	) : (
		<Spinner size={SpinnerSize.large} />
	);
};
