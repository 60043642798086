import {
	F4ROMember,
	F4ROMemberLogin,
	MemberLoginListItem,
} from "types/MemberTypes";
import { IF4roReadOnlyService, IF4roWriteService } from "services/f4roService";
import { truncDate } from "utils/DateUtil";

export interface IState {
	memberList?: F4ROMember[];
	memberLoginList: F4ROMemberLogin[] | null;
	displayDate: Date;
}

export const getInitialMemberLoginList = async (
	f4roReadOnlyService: IF4roReadOnlyService,
	state: IState,
	setState: React.Dispatch<Partial<IState>>
) => {
	const memberList =
		state.memberList || (await f4roReadOnlyService.getMemberList());
	const memberLoginList = await f4roReadOnlyService.getMemberLoginList(
		truncDate(state.displayDate)
	);
	const retrievedMemberLoginList: F4ROMemberLogin[] = memberLoginList.map(
		(item: MemberLoginListItem) => {
			const f4roMember = memberList.find(
				(member) => member.id === item.memberLookupId
			) as F4ROMember;
			return { id: item.id, member: f4roMember, loggedIn: true };
		}
	);
	const notLoggedInMembers = memberList.filter(
		(member) =>
			!retrievedMemberLoginList.find(
				(retrievedMember) => member.id === retrievedMember.member.id
			)
	);
	const joinedMemberLoginList = notLoggedInMembers
		.map((member) => ({ member, loggedIn: false }))
		.concat(retrievedMemberLoginList);

	const stateReducerArg: Partial<IState> = {
		memberLoginList: joinedMemberLoginList.sort((a, b) =>
			a.member.name.toUpperCase() < b.member.name.toUpperCase() ? -1 : 1
		),
	};
	if (!state.memberList) {
		stateReducerArg.memberList = memberList;
	}

	setState(stateReducerArg);
};

export const getHandleMemberLoginToggled = (
	f4roWriteService: IF4roWriteService | null,
	state: IState,
	setState: React.Dispatch<Partial<IState>>
): ((memberId: number, loginId?: number) => void) => {
	const authHandleMemberLoginToggledOff = (loginId: number) => {
		f4roWriteService!.setMemberDidNotLogin(loginId);
		const newMemberLoginList = state.memberLoginList!.map((item) => {
			if (item.id === loginId) {
				return { ...item, loggedIn: false, id: undefined };
			}

			return item;
		});
		setState({ memberLoginList: newMemberLoginList });
	};

	const authHandleMemberLoginToggledOn = (memberId: number) => {
		f4roWriteService!
			.setMemberDidLogin(memberId, truncDate(state.displayDate))
			.then((newId) => {
				const newMemberLoginList = state.memberLoginList!.map((item) => {
					if (item.member.id === memberId) {
						return { ...item, loggedIn: true, id: newId };
					}

					return item;
				});
				setState({ memberLoginList: newMemberLoginList });
			});
	};

	const authHandleMemberLoginToggled = (memberId: number, loginId?: number) => {
		if (loginId) {
			authHandleMemberLoginToggledOff(loginId);
		} else {
			authHandleMemberLoginToggledOn(memberId);
		}
	};

	const dummyHandleMemberLoginToggled = (
		memberId: number,
		loginId?: number
	) => {};

	const handleMemberLoginToggled = f4roWriteService
		? authHandleMemberLoginToggled
		: dummyHandleMemberLoginToggled;

	return handleMemberLoginToggled;
};
