import {
	PublicClientApplication,
	SilentRequest,
	AuthenticationResult,
	Configuration,
	LogLevel,
	AccountInfo,
	InteractionRequiredAuthError,
	EndSessionRequest,
	RedirectRequest,
	PopupRequest,
} from "@azure/msal-browser";

/**
 * Configuration class for @azure/msal-browser:
 * https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_config_configuration_.html
 */
const href = new URL(window.location.href);
const redirectUri = `${href.protocol}//${href.hostname}:${href.port}`;

const MSAL_CONFIG: Configuration = {
	auth: {
		clientId: "f39e852a-e961-4f7d-86be-946a960af9f2",
		authority:
			"https://login.microsoftonline.com/c06832ca-36b7-4cf2-8786-5184c9ab6a74",
		redirectUri,
	},
	cache: {
		cacheLocation: "localStorage", // This configures where your cache will be stored
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return;
				}
				switch (level) {
					case LogLevel.Error:
						console.error(message);
						return;
					case LogLevel.Info:
						console.info(message);
						return;
					case LogLevel.Verbose:
						console.debug(message);
						return;
					case LogLevel.Warning:
						console.warn(message);
						return;
				}
			},
		},
	},
};

const nullAccount: AccountInfo = {
	homeAccountId: "",
	environment: "",
	tenantId: "",
	username: "",
};

/**
 * AuthModule for application - handles authentication in app.
 */
export class AuthModule {
	private myMSALObj: PublicClientApplication; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/classes/_src_app_publicclientapplication_.publicclientapplication.html
	private account: AccountInfo; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-common/modules/_src_account_accountinfo_.html
	private loginRedirectRequest: RedirectRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_redirectrequest_.html
	private loginRequest: PopupRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_popuprequest_.html
	private profileRedirectRequest: RedirectRequest;
	private profileRequest: PopupRequest;
	private sitesRedirectRequest: RedirectRequest;
	private sitesRequest: PopupRequest;
	private silentProfileRequest: SilentRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_silentrequest_.html
	private silentSitesRequest: SilentRequest;

	constructor() {
		this.myMSALObj = new PublicClientApplication(MSAL_CONFIG);
		this.account = nullAccount;
		this.loginRequest = {
			scopes: [],
		};

		this.loginRedirectRequest = {
			...this.loginRequest,
			redirectStartPage: window.location.href,
		};

		this.profileRequest = {
			scopes: ["User.Read"],
		};

		this.profileRedirectRequest = {
			...this.profileRequest,
			redirectStartPage: window.location.href,
		};

		this.sitesRequest = {
			scopes: ["Sites.ReadWrite.All"],
		};

		this.sitesRedirectRequest = {
			...this.sitesRequest,
			redirectStartPage: window.location.href,
		};

		// Add here scopes for access token to be used at MS Graph API endpoints.
		this.silentProfileRequest = {
			scopes: ["openid", "profile", "User.Read"],
			account: nullAccount,
			forceRefresh: false,
		};

		this.silentSitesRequest = {
			scopes: ["openid", "profile", "Sites.ReadWrite.All"],
			account: nullAccount,
			forceRefresh: false,
		};
	}

	/**
	 * Calls getAllAccounts and determines the correct account to sign into, currently defaults to first account found in cache.
	 * TODO: Add account chooser code
	 *
	 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
	 */
	private getAccount(): AccountInfo {
		// need to call getAccount here?
		const currentAccounts = this.myMSALObj.getAllAccounts();
		if (currentAccounts === null) {
			console.log("No accounts detected");
			return nullAccount;
		}

		if (currentAccounts.length > 1) {
			// Add choose account code here
			console.log(
				"Multiple accounts detected, need to add choose account code."
			);
			return currentAccounts[0];
		} else if (currentAccounts.length === 1) {
			return currentAccounts[0];
		}

		return nullAccount;
	}

	/**
	 * Checks whether we are in the middle of a redirect and handles state accordingly. Only required for redirect flows.
	 *
	 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#redirect-apis
	 */
	loadAuthModule = (callback: (isAuthenticated: boolean) => void): void => {
		this.myMSALObj
			.handleRedirectPromise()
			.then((resp: AuthenticationResult | null) => {
				this.handleResponse(resp);
				callback(this.isAuthenticated());
			})
			.catch(console.error);
	};

	/**
	 * Handles the response from a popup or redirect. If response is null, will check if we have any accounts and attempt to sign in.
	 * @param response
	 */
	handleResponse = (response: AuthenticationResult | null) => {
		if (response) {
			this.account = response.account;
		} else {
			const account = this.getAccount();
			this.account = account;
		}
	};

	loginPopup = (): void => {
		this.myMSALObj
			.loginPopup(this.loginRequest)
			.then((resp: AuthenticationResult) => {
				this.handleResponse(resp);
			})
			.catch(console.error);
	};

	loginRedirect = (): void => {
		this.myMSALObj.loginRedirect(this.loginRedirectRequest);
	};

	/**
	 * Logs out of current account.
	 */
	logout = (): void => {
		const logOutRequest: EndSessionRequest = {
			account: this.account,
		};

		this.myMSALObj.logout(logOutRequest);
	};

	/**
	 * Gets the token to read user profile data from MS Graph silently, or falls back to interactive redirect.
	 */
	getProfileTokenRedirect = async (): Promise<string> => {
		this.silentProfileRequest.account = this.account;
		return this.getTokenRedirect(
			this.silentProfileRequest,
			this.profileRedirectRequest
		);
	};

	/**
	 * Gets the token to read user profile data from MS Graph silently, or falls back to interactive popup.
	 */
	getProfileTokenPopup = async (): Promise<string> => {
		this.silentProfileRequest.account = this.account;
		return this.getTokenPopup(this.silentProfileRequest, this.profileRequest);
	};

	getSitesTokenRedirect = async (): Promise<string> => {
		this.silentSitesRequest.account = this.account;
		return this.getTokenRedirect(
			this.silentSitesRequest,
			this.sitesRedirectRequest
		);
	};

	/**
	 * Gets the token to read user profile data from MS Graph silently, or falls back to interactive popup.
	 */
	getSitesTokenPopup = async (): Promise<string> => {
		this.silentSitesRequest.account = this.account;
		return this.getTokenPopup(this.silentSitesRequest, this.sitesRequest);
	};

	isAuthenticated = () => {
		return this.account.homeAccountId !== "";
	};

	userName = () => {
		return this.account.username;
	};

	/**
	 * Gets a token silently, or falls back to interactive popup.
	 */
	private async getTokenPopup(
		silentRequest: SilentRequest,
		interactiveRequest: PopupRequest
	): Promise<string> {
		try {
			const response: AuthenticationResult = await this.myMSALObj.acquireTokenSilent(
				silentRequest
			);
			return response.accessToken;
		} catch (e) {
			console.log("silent token acquisition fails: ", silentRequest);
			if (e instanceof InteractionRequiredAuthError) {
				return this.myMSALObj
					.acquireTokenPopup(interactiveRequest)
					.then((resp) => {
						return resp.accessToken;
					})
					.catch((err) => {
						console.error(err);
						throw Error(err?.toString());
					});
			} else {
				console.error(e);
			}
		}

		throw Error("Unable to retrieve token");
	}

	/**
	 * Gets a token silently, or falls back to interactive redirect.
	 */
	private async getTokenRedirect(
		silentRequest: SilentRequest,
		interactiveRequest: RedirectRequest
	): Promise<string> {
		try {
			const response = await this.myMSALObj.acquireTokenSilent(silentRequest);
			return response.accessToken;
		} catch (e) {
			console.log("silent token acquisition fails: ", silentRequest);
			if (e instanceof InteractionRequiredAuthError) {
				this.myMSALObj
					.acquireTokenRedirect(interactiveRequest)
					.catch(console.error);
			} else {
				console.error(e);
			}
		}

		throw Error("Unable to retrieve token");
	}
}

export const authModule = new AuthModule();
