import React from "react";

const Home = () => {
	/*	const [message, setMessage] = React.useState("No message yet");

	const callMessage = async () => {
		const response = await fetch("/api/message?name=Oren");
		// const response = await fetch("http://localhost:3001/api/message?name=Oren");
		const responseBody = await response.text();
		setMessage(responseBody);
	};

	return (
		<div>
			<button onClick={callMessage}>Call Message</button>
			<p>{message}</p>
		</div>
	);
*/

	return <div></div>;
};

export default Home;
