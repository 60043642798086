import {
	F4ROMember,
	MemberLoginListItem,
	AQMaster,
	AQTrackerListItem,
} from "types/MemberTypes";
import {
	setMemberDidLogin,
	setMemberDidNotLogin,
	setMemberDidNotParticipateInAQ,
	setMemberDidParticipateInAQ,
} from "./f4roService";

export { f4roReadOnlyService as anonF4roReadOnlyService } from "./f4roReadOnlyService";

export interface IF4roReadOnlyService {
	getMemberList: () => Promise<F4ROMember[]>;
	getMemberLoginList: (loginDate: Date) => Promise<MemberLoginListItem[]>;
	getMemberLoginCalendar: (memberId: number) => Promise<MemberLoginListItem[]>;
	getAQMaster: () => Promise<AQMaster[]>;
	getAQTracker: (aqMasterId: number) => Promise<AQTrackerListItem[]>;
	getMemberAQCalendar: (memberId: number) => Promise<AQTrackerListItem[]>;
}

export interface IF4roWriteService {
	setMemberDidNotLogin: (id: number) => Promise<void>;
	setMemberDidLogin: (memberId: number, loginDate: Date) => Promise<number>;
	setMemberDidNotParticipateInAQ: (aqTrackerId: number) => Promise<void>;
	setMemberDidParticipateInAQ: (
		memberId: number,
		aqMasterLookupId: number
	) => Promise<number>;
}

export const authF4roWriteService: IF4roWriteService = {
	setMemberDidNotLogin,
	setMemberDidLogin,
	setMemberDidNotParticipateInAQ,
	setMemberDidParticipateInAQ,
};

export { f4roReadOnlyService as authF4roReadOnlyService } from "./f4roService";
