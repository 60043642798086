import React, { useEffect, useState } from "react";
import { IF4roReadOnlyService } from "services/f4roService";
import { LoginCalendar } from "components";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";

interface IMemberLoginListCalendarContainerProps {
	memberId?: number;
	f4roReadOnlyService: IF4roReadOnlyService;
}

const MemberLoginCalendarContainer = ({
	memberId,
	f4roReadOnlyService,
}: IMemberLoginListCalendarContainerProps) => {
	const [loginList, setLoginList] = useState<Date[]>([]);

	useEffect(() => {
		if (memberId) {
			f4roReadOnlyService
				.getMemberLoginCalendar(memberId)
				.then((data) => setLoginList(data.map((item) => item.loginDate)));
		}
	}, [memberId, f4roReadOnlyService]);

	if (loginList) {
		return <LoginCalendar loginDates={loginList} />;
	}

	return <Spinner size={SpinnerSize.large} />;
};

export default MemberLoginCalendarContainer;
