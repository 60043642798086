import React, { useState } from "react";
import Calendar from "components/Calendar";
import { truncMonth, monthAfter, monthBefore } from "utils/DateUtil";

interface ILoginCalendarProps {
	loginDates: Date[];
}

export const LoginCalendar = ({ loginDates }: ILoginCalendarProps) => {
	const [month, setMonth] = useState(truncMonth(new Date()));

	return (
		<Calendar
			month={month}
			loginDates={loginDates}
			onNextMonth={() => setMonth(monthAfter(month))}
			onPreviousMonth={() => setMonth(monthBefore(month))}
		/>
	);
};
