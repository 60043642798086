import React, { useReducer, useEffect } from "react";
import { IF4roWriteService, IF4roReadOnlyService } from "services/f4roService";
import { MemberAQParticipationList } from "./MemberAQParticipationList";
import { Stack, Spinner, SpinnerSize } from "office-ui-fabric-react";
import {
	getInitialMemberAQParticipationList,
	getHandleMemberAQPartcipationToggled,
	IState,
} from "./memberAQParticipationListContainerUtils";

export interface IMemberAQParticipationListContainerProps {
	f4roReadOnlyService: IF4roReadOnlyService;
	f4roWriteService: IF4roWriteService | null;
	aqMasterId?: number;
}

type StateReducerArg = Partial<IState>;

type StateReducer = (state: IState, newState: StateReducerArg) => IState;
const stateReducer: StateReducer = (state, newState) => ({
	...state,
	...newState,
});
const initialState: IState = {
	memberAQParticipationList: null,
	aqMasterId: null,
};
const initialStateSetter = (state: IState) => state;

export const MemberAQParticipationListContainer = ({
	f4roReadOnlyService,
	f4roWriteService,
	aqMasterId,
}: IMemberAQParticipationListContainerProps) => {
	const [state, setState] = useReducer<StateReducer, IState>(
		stateReducer,
		initialState,
		initialStateSetter
	);

	useEffect(() => {
		if (aqMasterId && aqMasterId !== state.aqMasterId) {
			getInitialMemberAQParticipationList(
				f4roReadOnlyService,
				aqMasterId,
				state,
				setState
			);
		}
	}, [f4roReadOnlyService, aqMasterId, state]);

	const handleMemberAQParticipationToggled = getHandleMemberAQPartcipationToggled(
		f4roWriteService,
		aqMasterId || null,
		state,
		setState
	);

	return state.memberAQParticipationList ? (
		<Stack tokens={{ childrenGap: 5, padding: 10 }}>
			<MemberAQParticipationList
				memberAQParticipationList={state.memberAQParticipationList}
				onParticipationToggled={handleMemberAQParticipationToggled}
			/>
		</Stack>
	) : (
		<Spinner size={SpinnerSize.large} />
	);
};
