import React, { useReducer, useEffect } from "react";
import "./App.css";
import { Login } from "components/Login";
import { MainNavFromRoute } from "components/MainNav";
import { MemberLoginListContainer } from "scenes/LoginTracker";
import { MemberAQParticipationPageFromRoute } from "scenes/AQTracker";
import { Route, Switch } from "react-router-dom";
import { Stack, Spinner, SpinnerSize } from "office-ui-fabric-react";
import {
	anonF4roReadOnlyService,
	authF4roReadOnlyService,
	authF4roWriteService,
} from "services/f4roService";

import { MemberLoginCalendarPageFromRoute } from "scenes/LoginCalendar";
import { AQCalendarPageFromRoute } from "scenes/AQCalendar";
import { authModule } from "./services/auth/AuthModule";
import Home from "./Home";

interface IState {
	userName?: string;
	authenticationComplete: boolean;
}
type StateReducerArg = Partial<IState>;

type StateReducer = (state: IState, newState: StateReducerArg) => IState;

function App() {
	const [state, setState] = useReducer<StateReducer, IState>(
		(state, newState) => ({ ...state, ...newState }),
		{ userName: undefined, authenticationComplete: false },
		(initialState: IState) => initialState
	);
	const { userName, authenticationComplete } = state;

	useEffect(() => {
		authModule.loadAuthModule((isAuthenticated) => {
			const stateReducer: StateReducerArg = { authenticationComplete: true };
			if (isAuthenticated) {
				stateReducer.userName = authModule.userName();
			}
			setState(stateReducer);
		});
	}, []);

	const onLogin = () => {
		authModule.loginRedirect();
		if (authModule.isAuthenticated()) {
			setState({ userName: authModule.userName() });
		}
	};

	const onLogout = () => {
		authModule.logout();
		setState({ userName: undefined });
	};

	const serviceBundle = authenticationComplete
		? userName
			? {
					f4roReadOnlyService: authF4roReadOnlyService,
					f4roWriteService: authF4roWriteService,
			  }
			: { f4roReadOnlyService: anonF4roReadOnlyService, f4roWriteService: null }
		: null;

	return (
		<Stack tokens={{ childrenGap: 5, padding: 10 }}>
			<Login userName={userName} onLogin={onLogin} onLogout={onLogout} />
			<Stack horizontal>
				<Route path="/" component={MainNavFromRoute} />

				{serviceBundle ? (
					<Switch>
						<Route path="/login-tracker">
							<MemberLoginListContainer {...serviceBundle} />
						</Route>
						<Route
							path={["/login-calendar/:id", "/login-calendar"]}
							render={(props) => (
								<MemberLoginCalendarPageFromRoute
									{...props}
									{...serviceBundle}
								/>
							)}
						/>
						<Route
							path={["/aq-tracker/:id", "/aq-tracker"]}
							render={(props) => (
								<MemberAQParticipationPageFromRoute
									{...props}
									{...serviceBundle}
								/>
							)}
						></Route>
						<Route
							path={["/aq-calendar/:id", "/aq-calendar"]}
							render={(props) => (
								<AQCalendarPageFromRoute {...props} {...serviceBundle} />
							)}
						/>
						<Route path="/" component={Home}></Route>
					</Switch>
				) : (
					<Spinner size={SpinnerSize.large} />
				)}
			</Stack>
		</Stack>
	);
}

export default App;
