import {
  F4ROMember,
  AQMaster,
  AQTracker,
  AQTrackerListItem,
} from "types/MemberTypes";
import { IF4roReadOnlyService, IF4roWriteService } from "services/f4roService";

export interface IState {
  memberList?: F4ROMember[];
  aqMasterList?: AQMaster[];
  memberAQParticipationList: AQTracker[] | null;
  aqMasterId: number | null;
}

export const getInitialMemberAQParticipationList = async (
  f4roReadOnlyService: IF4roReadOnlyService,
  aqMasterId: number,
  state: IState,
  setState: React.Dispatch<Partial<IState>>
) => {
  const memberList =
    state.memberList || (await f4roReadOnlyService.getMemberList());
  const aqMasterList =
    state.aqMasterList || (await f4roReadOnlyService.getAQMaster());
  const memberAQParticipationList = await f4roReadOnlyService.getAQTracker(
    aqMasterId
  );

  const aqMaster = aqMasterList.find(
    (aqMasterItem) => aqMasterItem.id === aqMasterId
  ) as AQMaster;

  const retrievedMemberAQParticipationList: AQTracker[] = memberAQParticipationList.map(
    (item: AQTrackerListItem) => {
      const f4roMember = memberList.find(
        (member) => member.id === item.memberLookupId
      ) as F4ROMember;
      return {
        id: item.id,
        aqMaster,
        member: f4roMember,
        participated: true,
      };
    }
  );
  const notParticipatedMembers = memberList.filter(
    (member) =>
      !retrievedMemberAQParticipationList.find(
        (retrievedMember) => member.id === retrievedMember.member.id
      )
  );
  const joinedMemberLoginList: AQTracker[] = notParticipatedMembers
    .map((member) => ({ member, aqMaster, participated: false }))
    .concat(retrievedMemberAQParticipationList);

  const sortedaqParticipationList = joinedMemberLoginList.sort((a, b) =>
    a.member.name.toUpperCase() < b.member.name.toUpperCase() ? -1 : 1
  );

  const stateReducerArg: Partial<IState> = {
    memberAQParticipationList: sortedaqParticipationList,
    aqMasterId: aqMasterId,
  };

  if (!state.memberList) {
    stateReducerArg.memberList = memberList;
  }

  if (!state.aqMasterList) {
    stateReducerArg.aqMasterList = aqMasterList;
  }
  setState(stateReducerArg);
};

export const getHandleMemberAQPartcipationToggled = (
  f4roWriteService: IF4roWriteService | null,
  aqMasterId: number | null,
  state: IState,
  setState: React.Dispatch<Partial<IState>>
): ((memberId: number, aqTrackerId?: number) => void) => {
  const dummyHandleMemberAQParticipationToggled = (
    memberId: number,
    aqTrackerId?: number
  ) => {};

  if (aqMasterId === null) {
    return dummyHandleMemberAQParticipationToggled;
  }

  const authHandleMemberAQParticipationToggledOff = (aqTrackerId: number) => {
    f4roWriteService!.setMemberDidNotParticipateInAQ(aqTrackerId);
    const newMemberLoginList = state.memberAQParticipationList!.map((item) => {
      if (item.id === aqTrackerId) {
        return { ...item, participated: false, id: undefined };
      }

      return item;
    });
    setState({ memberAQParticipationList: newMemberLoginList });
  };

  const authHandleMemberAQParticipationToggledOn = (memberId: number) => {
    f4roWriteService!
      .setMemberDidParticipateInAQ(memberId, aqMasterId)
      .then((newId) => {
        const newMemberAQParticipationList = state.memberAQParticipationList!.map(
          (item) => {
            if (
              item.member.id === memberId &&
              item.aqMaster.id === aqMasterId
            ) {
              return { ...item, participated: true, id: newId };
            }

            return item;
          }
        );
        setState({ memberAQParticipationList: newMemberAQParticipationList });
      });
  };

  const authHandleMemberAQParticipationToggled = (
    memberId: number,
    aqTrackerId?: number
  ) => {
    if (aqTrackerId) {
      authHandleMemberAQParticipationToggledOff(aqTrackerId);
    } else {
      authHandleMemberAQParticipationToggledOn(memberId);
    }
  };

  const handleMemberAQParticipationToggled = f4roWriteService
    ? authHandleMemberAQParticipationToggled
    : dummyHandleMemberAQParticipationToggled;

  return handleMemberAQParticipationToggled;
};
