import React, { useEffect, useState } from "react";
import { IF4roReadOnlyService } from "services/f4roService";
import { LoginCalendar } from "components";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import { AQMaster } from "types/MemberTypes";
import { truncDate } from "utils/DateUtil";

interface IAQCalendarContainerProps {
	memberId?: number;
	f4roReadOnlyService: IF4roReadOnlyService;
}

const AQCalendarContainer = ({
	memberId,
	f4roReadOnlyService,
}: IAQCalendarContainerProps) => {
	const [loginList, setLoginList] = useState<Date[]>([]);
	const [aqMaster, setAQMaster] = useState<AQMaster[]>();

	useEffect(() => {
		f4roReadOnlyService.getAQMaster().then(setAQMaster);
	}, [f4roReadOnlyService]);

	useEffect(() => {
		const getDateFromAQMaster = (aqMasterLookupId: number): Date => {
			return truncDate(
				aqMaster!.find((item) => item.id === aqMasterLookupId)!.aqDate
			);
		};

		if (memberId && aqMaster) {
			f4roReadOnlyService.getMemberAQCalendar(memberId).then((data) => {
				setLoginList(
					data.map((item) => getDateFromAQMaster(item.aqMasterLookupId))
				);
			});
		}
	}, [memberId, f4roReadOnlyService, aqMaster]);

	if (loginList) {
		return <LoginCalendar loginDates={loginList} />;
	}

	return <Spinner size={SpinnerSize.large} />;
};

export default AQCalendarContainer;
