export function truncDate(dateWithTime: Date): Date {
	const newDate = new Date(
		Date.UTC(
			dateWithTime.getFullYear(),
			dateWithTime.getMonth(),
			dateWithTime.getDate()
		)
	);
	return newDate;
}

export function dayBefore(aDate: Date): Date {
	const newDate = new Date(aDate);
	newDate.setDate(aDate.getDate() - 1);
	return newDate;
}

export function dayAfter(aDate: Date): Date {
	const newDate = new Date(aDate);
	newDate.setDate(aDate.getDate() + 1);
	return newDate;
}

export function truncMonth(dateWithMonth: Date): Date {
	const newDate = new Date(
		Date.UTC(dateWithMonth.getUTCFullYear(), dateWithMonth.getUTCMonth(), 1)
	);
	return newDate;
}

export function monthBefore(aDate: Date): Date {
	const newDate = new Date(
		Date.UTC(aDate.getUTCFullYear(), aDate.getUTCMonth() - 1, 1)
	);
	return newDate;
}

export function monthAfter(aDate: Date): Date {
	const newDate = new Date(
		Date.UTC(aDate.getUTCFullYear(), aDate.getUTCMonth() + 1, 1)
	);
	return newDate;
}
