import { createUseStyles } from "react-jss";

const noMarginOrPadding = {
	margin: 0,
	padding: 0,
};

const standardSize = {
	width: "28px",
	height: "28px",
	lineHeight: "28px;",
	fontSize: "12px",
};

const dayStyle = {
	...standardSize,
	fontWeight: 400,
	...noMarginOrPadding,
	boxSizing: "border-box",
	WebkitBoxPack: "center",
	justifyContent: "center",
	WebkitBoxAlign: "center",
	alignItems: "center",
	cursor: "default",
	color: "#323130",
};

const useStyles = createUseStyles({
	loggedInCalendar: {
		backgroundColor: "lightgreen",
	},
	calendar: {
		"& *": {
			position: "relative",
			outline: "transparent",
			overflow: "visible",
		},
		width: "auto",
		boxSizing: "border-box",
		minHeight: "200px",
		margin: "-10px 0",
		padding: "10px 0",
		display: "block",
	},
	cHeader: {
		height: "28px",
		lineHeight: "28px",
		width: "100%",
		position: "relative",
		display: "inline-flex",
	},
	cMonthAndYear: {
		fontSize: "14px",
		color: "#323130",
		display: "inline-flex",
		WebkitBoxFlex: 1,
		flexGrow: 1,
		fontWeight: 600,
		padding: "0 5px",
	},
	cMonthComponents: {
		display: "inline-flex",
		alignSelf: "flex-end",
		"& button": {
			fontFamily: "inherit",
			display: "inline-block",
			...standardSize,
			textAlign: "center",
			color: "#323130",
			borderRadius: "2px",
			position: "relative",
			backgroundColor: "transparent",
			border: "none",
			padding: 0,
		},
	},
	cBody: {
		"& table": {
			textAlign: "center",
			borderCollapse: "collapse",
			borderSpacing: 0,
			tableLayout: "fixed",
			fontSize: "inherit",
			marginTop: "4px",
			width: "197px",
			"& thead": {
				"& tr": {
					"& th": {
						...dayStyle,
					},
				},
			},
			"& tbody": {
				"& tr": {
					"& td": {
						...dayStyle,
						borderRadius: "2px",
					},
				},
			},
		},
	},
});

export default useStyles;
