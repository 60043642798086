import React from "react";

import { Checkbox } from "office-ui-fabric-react";

export interface IMemberAQParticipationProps {
	name: string;
	participated: boolean;
	onParticipationToggled: () => void;
}

export const MemberAQParticipation = ({
	name,
	participated,
	onParticipationToggled,
}: IMemberAQParticipationProps) => {
	return (
		<Checkbox
			label={name}
			checked={participated}
			onChange={onParticipationToggled}
		/>
	);
};
