import React from "react";
import { Checkbox } from "office-ui-fabric-react";

export interface IMemberLoginProps {
	name: string;
	loggedIn: boolean;
	onLoginToggled: () => void;
}

export const MemberLogin = ({
	name,
	loggedIn,
	onLoginToggled,
}: IMemberLoginProps) => {
	return <Checkbox label={name} checked={loggedIn} onChange={onLoginToggled} />;
};
