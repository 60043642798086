import { Client } from "@microsoft/microsoft-graph-client";
import {
	F4ROMember,
	MemberLoginListItem,
	AQMaster,
	AQTrackerListItem,
} from "types/MemberTypes";
import { dayAfter } from "utils/DateUtil";
import { IF4roReadOnlyService } from "./";
import { authModule, MsalAuthenticationProvider } from "services/auth";
import { mapObject, Map } from "map-object";

const getClient = () => {
	const authProvider = new MsalAuthenticationProvider(authModule);
	const client = Client.initWithMiddleware({ authProvider });

	return client;
};

let _memberList: F4ROMember[];

const mapInt = (objectAKey: string) => (
	objectA: Record<string, unknown>
): unknown => Number.parseInt(objectA[objectAKey] as string);
const mapField = (objectAKey: string) => (
	objectA: Record<string, unknown>
): unknown => (objectA.fields as Record<string, unknown>)[objectAKey];
const mapFieldInt = (objectAKey: string) => (
	objectA: Record<string, unknown>
): unknown =>
	Number.parseInt(
		(objectA.fields as Record<string, unknown>)[objectAKey] as string
	);
const mapFieldDate = (objectAKey: string) => (
	objectA: Record<string, unknown>
): unknown =>
	new Date((objectA.fields as Record<string, unknown>)[objectAKey] as string);

const getMemberList = async (): Promise<F4ROMember[]> => {
	if (!_memberList) {
		const map: Map = {
			id: mapInt("id"),
			name: mapField("Title"),
		};
		const client = getClient();
		const response = await client
			.api("/sites/youvereachedme.sharepoint.com:/sites/MCOC:/lists/F4RO/items")
			.expand("fields($select=Title)")
			.get();
		const unsortedList = mapObject(response.value, map) as F4ROMember[];
		_memberList = unsortedList.sort((a, b) =>
			a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
		);
	}

	return _memberList;
};

const getMemberLoginList = async (
	loginDate: Date
): Promise<MemberLoginListItem[]> => {
	const map: Map = {
		id: mapInt("id"),
		memberLookupId: mapFieldInt("MemberLookupId"),
		loginDate: mapFieldDate("LoginDate"),
	};
	const dayAfterLoginDate = dayAfter(loginDate);
	const filter = `fields/LoginDate ge '${loginDate.toISOString()}' and fields/LoginDate lt '${dayAfterLoginDate.toISOString()}'`;
	const client = getClient();
	const query = client
		.api(
			"/sites/youvereachedme.sharepoint.com:/sites/MCOC:/lists/F4RO Login Tracker/items"
		)
		.filter(filter)
		.expand("fields($select=MemberLookupId,LoginDate)");
	const response = await query
		.header("Prefer", "HonorNonIndexedQueriesWarningMayFailRandomly")
		.get();
	const list = mapObject(response.value, map);
	return list as MemberLoginListItem[];
};

export const setMemberDidLogin = async (
	memberId: number,
	loginDate: Date
): Promise<number> => {
	const client = getClient();
	const fields = {
		fields: {
			MemberLookupId: memberId,
			LoginDate: loginDate.toISOString(),
		},
	};

	const response = await client
		.api(
			"/sites/youvereachedme.sharepoint.com:/sites/MCOC:/lists/F4RO Login Tracker/items"
		)
		.create(fields);

	return Number.parseInt(response.id);
};

export const setMemberDidNotLogin = async (id: number) => {
	const client = getClient();
	await client
		.api(
			`/sites/youvereachedme.sharepoint.com:/sites/MCOC:/lists/F4RO Login Tracker/items/${id}`
		)
		.delete();
};

const getMemberLoginCalendar = async (
	memberId: number
): Promise<MemberLoginListItem[]> => {
	const client = getClient();
	const response = await client
		.api(
			"/sites/youvereachedme.sharepoint.com:/sites/MCOC:/lists/F4RO Login Tracker/items"
		)
		.filter(`fields/MemberLookupId eq ${memberId}`)
		.expand("fields($select=LoginDate)")
		.get();
	return response.value.map((item: any) => ({
		id: item.id,
		memberLookupId: memberId,
		loginDate: new Date(item.fields.LoginDate),
	}));
};

export const setMemberDidNotParticipateInAQ = async (
	aqTrackerLookupId: number
): Promise<void> => {
	const client = getClient();
	await client
		.api(
			`/sites/youvereachedme.sharepoint.com:/sites/MCOC:/lists/F4RO AQ Tracker/items/${aqTrackerLookupId}`
		)
		.delete();
};

export const setMemberDidParticipateInAQ = async (
	memberId: number,
	aqMasterLookupId: number
): Promise<number> => {
	const client = getClient();
	const fields = {
		fields: {
			MemberLookupId: memberId,
			AQ_x0020_Start_x0020_DateLookupId: aqMasterLookupId,
		},
	};

	const response = await client
		.api(
			"/sites/youvereachedme.sharepoint.com:/sites/MCOC:/lists/F4RO AQ Tracker/items"
		)
		.create(fields);

	return Number.parseInt(response.id);
};

let _aqMaster: AQMaster[];

const getAQMaster = async (): Promise<AQMaster[]> => {
	if (!_aqMaster) {
		const client = getClient();
		const response = await client
			.api(
				"/sites/youvereachedme.sharepoint.com:/sites/MCOC:/lists/F4RO AQ Master/items"
			)
			.expand("fields($select=StartDate)")
			.get();
		const unsortedList: AQMaster[] = response.value.map((item: any) => ({
			id: Number.parseInt(item.id),
			aqDate: new Date(item.fields.StartDate),
		}));
		_aqMaster = unsortedList.sort((a, b) => (a.aqDate > b.aqDate ? 1 : -1));
	}

	return _aqMaster;
};

const getAQTracker = async (
	aqMasterId: number
): Promise<AQTrackerListItem[]> => {
	const client = getClient();
	const response = await client
		.api(
			"/sites/youvereachedme.sharepoint.com:/sites/MCOC:/lists/F4RO AQ Tracker/items"
		)
		.filter(`fields/AQ_x0020_Start_x0020_DateLookupId eq ${aqMasterId}`)
		.expand("fields($select=MemberLookupId)")
		.get();
	return response.value.map((item: any) => ({
		id: item.id,
		aqMasterLookupId: aqMasterId,
		memberLookupId: Number.parseInt(item.fields.MemberLookupId),
	}));
};

const getMemberAQCalendar = async (
	memberId: number
): Promise<AQTrackerListItem[]> => {
	const client = getClient();
	const response = await client
		.api(
			"/sites/youvereachedme.sharepoint.com:/sites/MCOC:/lists/F4RO AQ Tracker/items"
		)
		.filter(`fields/MemberLookupId eq ${memberId}`)
		.expand("fields($select=AQ_x0020_Start_x0020_DateLookupId)")
		.get();
	return response.value.map((item: any) => ({
		id: item.id,
		aqMasterLookupId: Number.parseInt(
			item.fields.AQ_x0020_Start_x0020_DateLookupId
		),
		memberLookupId: memberId,
	}));
};

export const f4roReadOnlyService: IF4roReadOnlyService = {
	getMemberList,
	getMemberLoginList,
	getMemberLoginCalendar,
	getAQMaster,
	getAQTracker,
	getMemberAQCalendar,
};
