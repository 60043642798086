import React from "react";
import AQDropdown from "./AQDropdown";
import { MemberAQParticipationListContainer } from "./MemberAQParticipationListContainer";
import { Stack } from "office-ui-fabric-react";
import { useHistory } from "react-router-dom";
import { match } from "react-router";
import { IF4roReadOnlyService, IF4roWriteService } from "services/f4roService";

export interface IMemberAQParticipationPageProps {
	f4roReadOnlyService: IF4roReadOnlyService;
	f4roWriteService: IF4roWriteService | null;
	aqMasterId?: number;
}
export const MemberAQParticipationPage = ({
	f4roReadOnlyService,
	f4roWriteService,
	aqMasterId,
}: IMemberAQParticipationPageProps) => {
	const history = useHistory();

	const handleAQMasterSelected = (aqMasterId: number) => {
		history.push(`/aq-tracker/${aqMasterId}`);
	};

	return (
		<Stack>
			<Stack>
				<AQDropdown
					aqMasterId={aqMasterId}
					aqMasterSelected={handleAQMasterSelected}
					f4roReadOnlyService={f4roReadOnlyService}
				/>

				<MemberAQParticipationListContainer
					aqMasterId={aqMasterId}
					f4roReadOnlyService={f4roReadOnlyService}
					f4roWriteService={f4roWriteService}
				/>
			</Stack>
		</Stack>
	);
};

export interface IMemberAQParticipationPageFromRouteProps {
	match: match<{ id: string }>;
	f4roReadOnlyService: IF4roReadOnlyService;
	f4roWriteService: IF4roWriteService | null;
}

export const MemberAQParticipationPageFromRoute = (
	props: IMemberAQParticipationPageFromRouteProps
) => {
	const match = props.match;
	const aqMasterId =
		match && !isNaN(Number.parseInt(match.params.id))
			? Number.parseInt(match.params.id)
			: undefined;

	return MemberAQParticipationPage({ ...props, aqMasterId });
};
