import React from "react";
import useStyles from "./calendarStyles";
import { IconButton } from "office-ui-fabric-react";

interface ICalendarProps {
	loginDates?: Date[];
	month: Date;
	onPreviousMonth: () => void;
	onNextMonth: () => void;
}

const monthName = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

const Calendar2 = ({
	loginDates,
	month,
	onPreviousMonth,
	onNextMonth,
}: ICalendarProps) => {
	const classes = useStyles();

	const firstDayOfMonth = month.getUTCDay();
	const monthNumber = month.getUTCMonth();
	const loginDatesThisMonth = loginDates?.filter(
		(item) => item.getUTCMonth() === monthNumber
	);

	let monthGrid: string[][] = new Array<string[]>(6);
	for (let i = 0; i < 6; i++) {
		monthGrid[i] = new Array<string>(7);
		monthGrid[i].fill("");
	}
	let currentDate = new Date(month);
	let week: number;
	do {
		let dateOfMonth = currentDate.getUTCDate();
		let dayOfWeek = currentDate.getUTCDay();
		week = Math.floor((firstDayOfMonth + dateOfMonth - 1) / 7);
		monthGrid[week][dayOfWeek] = dateOfMonth.toString();
		currentDate.setUTCDate(dateOfMonth + 1);
	} while (currentDate.getUTCMonth() === monthNumber);

	monthGrid = monthGrid.slice(0, week + 1);

	const monthGridEntryClassName = (dayOfMonth: string) => {
		if (!(loginDatesThisMonth && dayOfMonth)) {
			return undefined;
		}
		const dayOfMonthInt = Number.parseInt(dayOfMonth);
		if (
			loginDatesThisMonth.findIndex(
				(loginDate) => dayOfMonthInt === loginDate.getUTCDate()
			) >= 0
		) {
			return classes.loggedInCalendar;
		}

		return undefined;
	};

	return (
		<div className={classes.calendar}>
			<div className={classes.cHeader}>
				<div className={classes.cMonthAndYear}>{`${
					monthName[month.getUTCMonth()]
				} ${month.getUTCFullYear()}`}</div>
				<div className={classes.cMonthComponents}>
					<IconButton
						iconProps={{ iconName: "Up" }}
						onClick={onPreviousMonth}
					/>
					<IconButton iconProps={{ iconName: "Down" }} onClick={onNextMonth} />
				</div>
			</div>
			<div className={classes.cBody}>
				<table>
					<thead>
						<tr>
							<th>S</th>
							<th>M</th>
							<th>T</th>
							<th>W</th>
							<th>T</th>
							<th>F</th>
							<th>S</th>
						</tr>
					</thead>
					<tbody>
						{monthGrid.map((week, weekIndex) => (
							<tr key={`w${weekIndex}`}>
								{week.map((day, dayIndex) => (
									<td
										className={monthGridEntryClassName(day)}
										key={`w${weekIndex}d${dayIndex}`}
									>
										{day}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default Calendar2;
