import {
	AuthenticationProviderOptions,
	AuthenticationProvider,
} from "@microsoft/microsoft-graph-client";
import { AuthModule } from "./AuthModule";

export class MsalAuthenticationProvider implements AuthenticationProvider {
	private readonly _authModule: AuthModule;

	constructor(authModule: AuthModule) {
		this._authModule = authModule;
		this.getAccessToken = this.getAccessToken.bind(this);
	}

	public getAccessToken(
		authenticationProviderOptions?: AuthenticationProviderOptions
	): Promise<string> {
		return this._authModule.getSitesTokenRedirect();
	}
}
