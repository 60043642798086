import React from "react";
import { MemberDropdown } from "components";
import MemberLoginCalendarContainer from "./MemberLoginCalendarContainer";
import { Stack } from "office-ui-fabric-react";
import { useHistory } from "react-router-dom";
import { match } from "react-router";
import { IF4roReadOnlyService, IF4roWriteService } from "services/f4roService";

export interface IMemberLoginCalendarPageProps {
	f4roReadOnlyService: IF4roReadOnlyService;
	memberId?: number;
}
export const MemberLoginCalendarPage = ({
	f4roReadOnlyService,
	memberId,
}: IMemberLoginCalendarPageProps) => {
	const history = useHistory();

	const handleMemberSelected = (memberId: number) => {
		history.push(`/login-calendar/${memberId}`);
	};

	return (
		<Stack>
			<Stack>
				<MemberDropdown
					memberId={memberId}
					memberSelected={handleMemberSelected}
					f4roReadOnlyService={f4roReadOnlyService}
				/>

				<MemberLoginCalendarContainer
					memberId={memberId}
					f4roReadOnlyService={f4roReadOnlyService}
				/>
			</Stack>
		</Stack>
	);
};

export interface IMemberLoginCalendarPageFromRouteProps {
	match: match<{ id: string }>;
	f4roReadOnlyService: IF4roReadOnlyService;
	f4roWriteService: IF4roWriteService | null;
}

export const MemberLoginCalendarPageFromRoute = (
	props: IMemberLoginCalendarPageFromRouteProps
) => {
	const match = props.match;
	const memberId =
		match && !isNaN(Number.parseInt(match.params.id))
			? Number.parseInt(match.params.id)
			: undefined;

	return MemberLoginCalendarPage({ ...props, memberId });
};
