import React, { useEffect, useState } from "react";
import {
	Dropdown,
	Spinner,
	IDropdownOption,
	Stack,
	IconButton,
} from "office-ui-fabric-react";
import { F4ROMember } from "types/MemberTypes";
import { IF4roReadOnlyService } from "services/f4roService";

interface IMemberDropdownProps {
	memberId?: number;
	f4roReadOnlyService: IF4roReadOnlyService;
	memberSelected: (memberId: number) => void;
}

export const MemberDropdown = ({
	memberId,
	f4roReadOnlyService,
	memberSelected,
}: IMemberDropdownProps) => {
	const [memberList, setMemberList] = useState<F4ROMember[]>();

	useEffect(() => {
		f4roReadOnlyService.getMemberList().then((data) => setMemberList(data));
	}, [f4roReadOnlyService]);

	useEffect(() => {
		if (memberList && memberList.length > 0) {
			if (memberId === undefined) {
				memberSelected(memberList[0].id);
			} else if (
				memberList.findIndex((member) => {
					return member.id === memberId;
				}) < 0
			) {
				memberSelected(memberList[0].id);
			}
		}
	}, [memberList, memberId, memberSelected]);

	function handleOnChange(
		ev: React.FormEvent<HTMLDivElement>,
		option?: IDropdownOption
	) {
		if (option) {
			const key = option.key as number;
			memberSelected(key);
		}
	}

	const handleChevronClick = (leftClick: boolean) => {
		if (!memberList) {
			return;
		}

		let index = memberList.findIndex((member) => member.id === memberId);
		if (index < 0) {
			return;
		}

		if (leftClick) {
			if (index === 0) {
				index = memberList.length - 1;
			} else {
				index--;
			}
		} else {
			if (index + 1 === memberList.length) {
				index = 0;
			} else {
				index++;
			}
		}

		memberSelected(memberList[index].id);
	};

	if (memberList) {
		return (
			<Stack horizontal horizontalAlign="space-between">
				<IconButton
					iconProps={{ iconName: "ChevronLeft" }}
					onClick={() => handleChevronClick(true)}
				/>
				<Dropdown
					dropdownWidth={500}
					styles={{ root: { width: "133px" } }}
					selectedKey={memberId}
					options={memberList.map((member) => ({
						key: member.id,
						text: member.name,
					}))}
					onChange={handleOnChange}
				/>
				<IconButton
					iconProps={{ iconName: "ChevronRight" }}
					onClick={() => handleChevronClick(false)}
				/>
			</Stack>
		);
	}
	return <Spinner />;
};
